import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Link, useLocation } from "react-router-dom";
import gsap from "gsap";

const NAV_ITEMS = [
  {
    label: "About",
    slug: "/about/",
  },
  {
    label: "Work",
    slug: "/work/",
  },
  {
    label: "Journal",
    slug: "/article/",
  },
];

const Search = forwardRef(({ searchValue, handleKeywordChange, handleSearchSubmit, handleToggleAcknowledgement, showAcknowledgement }, ref) => {
  const searchField = useRef(null);
  const searchRef = useRef(null);
  const contactRef = useRef(null);
  const contactBtnRef = useRef(null);
  const acknowledgementBtnRef = useRef(null);
  const navItemsRefs = useRef([]);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [isMobile, setIsMobile] = useState(window.matchMedia("screen and (max-width: 767px)").matches);

  useImperativeHandle(ref, () => ({
    focusAcknowledgeBtn: () => {
      if (acknowledgementBtnRef.current) {
        acknowledgementBtnRef.current.focus();
      }
    },
    focusSearchField: () => {
      if (searchField.current) {
        searchField.current.focus();
      }
    },
  }));

  useEffect(() => {
    const currentPath = location.pathname;
    const foundItem = NAV_ITEMS.find((item) => item.slug === currentPath);
    const subPage = currentPath.split("/")[1];
    const fondSubpage = subPage.length > 1 ? NAV_ITEMS.find((item) => item.slug.includes(subPage)) : null;

    if (foundItem) {
      setActiveItem(foundItem.label);
    } else if (fondSubpage) {
      setActiveItem(fondSubpage.label);
    } else {
      setActiveItem(null);
    }

    if (contactBtnRef.current.classList.contains("active")) {
      handleContact();
    }

    if (searchRef.current.classList.contains("active")) {
      handleCloseSearch();
    }
  }, [location]);

  useEffect(() => {
    if (isMobile && searchValue.length === 0 && searchRef.current.classList.contains("active") && !searchField.current.matches(":focus")) {
      handleCloseSearch();
    }
  }, [searchValue]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    handleKeywordChange(value);
    if (value.length >= 3) {
      handleSearchSubmit();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchSubmit(true);
    }
  };

  const handleContact = () => {
    const contactInfo = contactRef.current;
    contactBtnRef.current.classList.toggle("active");
    gsap.to(contactInfo, {
      height: contactInfo.clientHeight === 32 ? "auto" : 32,
      bottom: contactInfo.clientHeight === 32 ? 65 : -contactInfo.clientHeight,
      duration: 0.3,
      ease: "power2.inOut",
    });

    if (showAcknowledgement) {
      handleToggleAcknowledgement();
    }
  };

  const handleResetContact = () => {
    const contactInfo = contactRef.current;
    contactBtnRef.current.classList.remove("active");
    gsap.to(contactInfo, {
      height: "auto",
      bottom: "unset",
    });
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (contactBtnRef.current.classList.contains("active") && !contactBtnRef.current.contains(e.target)) {
        handleContact();
      }
      if (searchValue.length === 0 && searchRef.current.classList.contains("active") && !searchRef.current.contains(e.target)) {
        handleCloseSearch();
      }
    };

    const handleResize = () => {
      if (window.matchMedia("screen and (max-width: 919px)").matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);

        if (contactBtnRef.current.classList.contains("active")) {
          handleResetContact();
        }

        if (searchRef.current.classList.contains("active")) {
          searchRef.current.classList.remove("active");
          searchField.current.width = "auto";
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile, searchValue]);

  const handleClickSearch = () => {
    const searchInput = searchField.current;
    const isExpanded = searchRef.current.classList.contains("active");

    if (!isExpanded) {
      searchRef.current.classList.toggle("active");
      gsap.to(searchInput, {
        width: isExpanded ? 0 : "100%",
        duration: 0.3,
        ease: "power2.inOut",
      });
      searchInput.focus();
    }
  };

  const handleCloseSearch = () => {
    if (isMobile) {
      searchRef.current.classList.toggle("active");

      gsap.to(searchField.current, {
        width: 0,
        duration: 0.3,
        ease: "power2.inOut",
      });
    }
  };

  const handleClearSearch = () => {
    handleKeywordChange("");
    searchField.current.focus();
  };

  const handleClickAcknowledge = () => {
    if (searchValue.length >= 3) {
      handleClearSearch();
    }
    handleToggleAcknowledgement();
  };

  const handleNavKeyPress = (e, idx) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.click();
      e.target.blur(); // remove focus after click for a11y
    } else if (e.key === "ArrowDown") {
      console.log(navItemsRefs.current, idx);
      e.preventDefault();
      if (idx < navItemsRefs.current.length - 1) {
        navItemsRefs.current[idx + 1].focus();
      } else {
        navItemsRefs.current[0].focus();
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (idx > 0) {
        navItemsRefs.current[idx - 1].focus();
      } else {
        navItemsRefs.current[navItemsRefs.current.length - 1].focus();
      }
    }
  };

  return (
    <>
      <Link to={"/"} className="logo desktop">
        Denim &amp; Steel
      </Link>
      <div className="sidebar-nav">
        <nav className="nav">
          <ul>
            {NAV_ITEMS.map((nav, idx) => (
              <li key={nav.slug} className={activeItem === nav.label ? "active" : ""} aria-current={activeItem === nav.label ? "page" : null}>
                <Link
                  to={nav.slug}
                  key={nav.slug}
                  className="link"
                  onKeyDown={(e) => handleNavKeyPress(e, idx)}
                  ref={(el) => (navItemsRefs.current[idx] = el)}
                >
                  {nav.label}
                </Link>
              </li>
            ))}
            <li className="nav-contact" ref={contactBtnRef}>
              <button className="button" onClick={handleContact}>
                Contact
              </button>
            </li>
          </ul>
        </nav>
        <div className="sidebar-search" ref={searchRef}>
          <Link to={"/"} className="logo mobile">
            Denim &amp; Steel
          </Link>
          <div className="input-cover" onClick={handleClickSearch}>
            <input
              id="search-input"
              aria-label="Search"
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              className="search-input"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              type="search"
              spellCheck="false"
              ref={searchField}
            />
          </div>
        </div>
      </div>

      <div className="contactInfo" ref={contactRef}>
        <p className="has-xx-small-font-size contactInfo-detail">
          <span className="small-logo"></span>
          <span>
            <strong style={{ fontWeight: "bold" }}>Denim &amp; Steel Interactive</strong>
            <br />
            Est. 2011
          </span>
        </p>
        <p className="has-xx-small-font-size">
          4643 Main Street
          <br />
          Vancouver, BC V5V 3R6
          <br />
          <div
            className="acknowledgement"
            onClick={handleClickAcknowledge}
            role="button"
            tabIndex="0"
            ref={acknowledgementBtnRef}
            onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && handleClickAcknowledge()}
          >
            We live &amp; work on stolen land
          </div>
        </p>

        <p>
          <a className="has-xx-small-font-size button" href="mailto:hello@denimandsteel.com">
            hello@denimandsteel.com
          </a>
          <br />
          {/* <a href="https://www.linkedin.com/company/denim-and-steel" className="has-xx-small-font-size">
            Follow us on LinkedIn
          </a> */}
        </p>
      </div>
    </>
  );
});

export default Search;
