import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import Store from "../../Store";
import LoadingSpinner from "../LoadingSpinner";
import Fuse from "fuse.js";
import Result from "../Result";
import gsap from "gsap";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 1000,
  maxPatternLength: 48,
  minMatchCharLength: 3,
  keys: ["title", "synonyms", "excerpt"],
};

const Search = forwardRef(({ showSearch, handleClose, keyword }, ref) => {
  const [searchResults, setSearchResults] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const closeBtn = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      closeBtn.current.focus();
    },
  }));

  useEffect(() => {
    const fetchSearchIndex = async () => {
      const searchIndex = await Store.getSearchIndex();
      const newFuse = new Fuse(searchIndex, fuseOptions);
      setFuse(newFuse);
      setLoading(false);
    };

    fetchSearchIndex();
  }, []);

  useEffect(() => {
    if (fuse && keyword) {
      const results = fuse.search(keyword);
      const filteredResults = results.filter((result) => result.type !== "keyword");
      setSearchResults(filteredResults);
    }
  }, [fuse, keyword]);

  useEffect(() => {
    if (showSearch) {
      setIsFinished(true);
      gsap.to(".slideInPanel--search", {
        visibility: "visible",
        autoAlpha: 1,
        pointerEvents: "auto",
        transform: "translateY(0)",
        duration: 0.3,
        ease: "power2.inOut",
      });
    } else {
      gsap.to(".slideInPanel--search", {
        autoAlpha: 0,
        transform: "translateY(-100%)",
        pointerEvents: "none",
        duration: 0.3,
        ease: "power2.inOut",
        onComplete: () => {
          setIsFinished(false);
        },
      });
    }
  }, [showSearch]);

  useEffect(() => {
    if (isFinished && searchResults.length > 0) {
      // need to do something here, not sure if ref works for here
      const focusableElements = document.querySelectorAll(".slideInPanel--search .search-results a");
      const firstFocusableElement = closeBtn.current;
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      const trapFocus = (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          if (document.activeElement === lastFocusableElement) {
            e.preventDefault();
            firstFocusableElement.focus();
          }
        }
        if (e.shiftKey && e.key === "Tab") {
          if (document.activeElement === firstFocusableElement) {
            e.preventDefault();
            lastFocusableElement.focus();
          }
        }
        if (e.key === "Escape") {
          handleClose();
        }
      };

      document.addEventListener("keydown", trapFocus);

      return () => {
        document.removeEventListener("keydown", trapFocus);
      };
    }
  }, [isFinished, searchResults]);

  return (
    <dialog className="content slideInPanel slideInPanel--search" open={isFinished} aria-hidden={!showSearch}>
      <div className="slideInPanel-header">
        <button className="close-items" aria-label="Close search" onClick={handleClose} ref={closeBtn}>
          <span className="close-text" aria-hidden>
            <span aria-hidden>&times;</span> Close
          </span>
        </button>
      </div>
      <div className="post-content">
        <h1>Search: {keyword}</h1>
        {loading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <div className="search-results">
            {searchResults.length > 0 ? (
              <ul>
                {searchResults.map((result) => (
                  <Result key={result.id} result={result} type={result.type} />
                ))}
              </ul>
            ) : (
              <p>No results found for "{keyword}"</p>
            )}
          </div>
        )}
      </div>
    </dialog>
  );
});

export default Search;
