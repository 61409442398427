import React, { Component } from "react";
import balanceText from "balance-text";
import Types from "../types";
import Store from "../../Store";
import classNames from "classnames";
import LoadingSpinner from "../LoadingSpinner";
import gsap from "gsap";

const post_type_base_url = {
  work: "works",
  person: "people",
  page: "pages",
  snippet: "snippets",
  post: "posts",
};

class ContentPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
      isLoading: true,
    };
  }

  fetchData = (post_type, post_slug) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        Store.getPostBySlug(post_type_base_url[post_type], post_slug).then((res) => {
          this.props.onContentLoad(res[0]);
          document.title = res[0].title.rendered.replace("&#038;", "&") + " — Denim & Steel";
          this.setState({
            post: res[0],
            isLoading: false,
          });
        });
      }
    );
  };

  handleClick = (e) => {
    const target = e.target;

    if (target.classList.contains("accordion-toggle")) {
      const accordion = target.closest(".accordion");
      const accordionBody = accordion.querySelector(".accordion-body");
      const expanded = target.getAttribute("aria-expanded") === "true" ? "false" : "true";

      target.setAttribute("aria-expanded", expanded);
      gsap.to(accordionBody, {
        height: expanded === "true" ? "auto" : 0,
        duration: 0.3,
        ease: "power2.inOut",
      });
    }
  };

  componentDidMount() {
    this._ismounted = true;
    this.fetchData(this.props.type, this.props.slug);
    balanceText();

    document.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    this._ismounted = false;
    document.title = "Denim & Steel";

    document.removeEventListener("click", this.handleClick);
  }

  componentDidUpdate(prevProps) {
    if (this.props.slug !== prevProps.slug) {
      this.fetchData(this.props.type, this.props.slug);
      window.scrollTo(0, 0);
      balanceText();
    }
  }

  render() {
    const { post, device } = this.state;
    const { isLoggedIn, closeButtonRef } = this.props;
    console.log(post);

    return (
      <div className={classNames("content", `post-id-${post.id}`, `post-${this.props.slug}`)} aria-hidden={this.props.ariaHidden}>
        {/* Invisible close button for sr users */}
        <button className="close-items visually-hidden" onClick={this.props.onClick} aria-label="Close and return to list" autoFocus />
        {this.state.isLoading && <LoadingSpinner />}
        {isLoggedIn && (
          <div className="edit-links">
            <a className="edit admin-link" href={`/wp-admin/post.php?post=${post.id}&action=edit`}>
              Edit
            </a>
          </div>
        )}
        {/* <button className="close-items" aria-label="Close and return to list" ref={closeButtonRef} onClick={this.props.onClick}>
          <span className="close-content" />
          <span className="close-text">close</span>
        </button> */}
        {!this.state.isLoading &&
          React.cloneElement(typeof Types[post.slug] !== "undefined" ? Types[post.slug] : Types[post.type], {
            post: post,
            device: this.props.device,
          })}
        {device === "desktop" && <div className="shield" onClick={this.props.onClick}></div>}
      </div>
    );
  }
}

export default ContentPane;
