import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Store from "../../Store";
import Result from "../Result";

export default function ResultsPane({ type, onClick }) {
  const [results, setResults] = useState([]);

  useEffect(() => {
    Store.getSearchIndex().then((searchIndex) => {
      const keyword = searchIndex.find((o) => o.type === "keyword" && o.slug === type);
      setResults(keyword.related);
      window.scrollTo(0, 0);
    });
  }, [type]);

  return (
    <div className={classNames("results results-sidebar")}>
      <h1 className="visually-hidden">{type === "work" ? "Work" : "Journal"}</h1>
      <button className="close-items visually-hidden" onClick={onClick} aria-label="Close and return to list" autoFocus />
      <ul>
        {results.map((result, idx) => (
          <Result key={idx} result={result} type={type} />
        ))}
      </ul>
    </div>
  );
}
